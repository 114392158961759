import React, { ReactElement } from 'react';

import { TFunction } from 'i18next';

import WordsLimitV2ExplanationText from '~/modules/tools/components/WordsLimitV2/ExplanationText';
import analytics from '~/services/analytics';

import { HIGHLIGHT, HighLightConfigType } from '../highlight/constants';

export const ToolsSidebarKey = 'tools';

export const ToolsGalleryPath = '/tools';

export const getToolsGalleryTitle = (t: TFunction): string =>
  t('general:tools', 'Tools');

export const AdvancedArticleGeneratorPath = '/tools/advanced_article_generator';

export const getAdvancedArticleGeneratorTitle = (t: TFunction): string =>
  t('tools:title.long_article_generator', 'Long Article (1000+ Words)');

export const PaperWriterPath = '/tools/paper_writer';

export const getPaperWriterTitle = (t: TFunction): string =>
  t('tools:title.paper_writer', 'Paper Writer');

export const PlagiarismCheckerPath = '/tools/plagiarism_checker';

export const getPlagiarismCheckerTitle = (t: TFunction): string =>
  t('tools:title.plagiarism_checker', 'Plagiarism Checker');

export const AiDetectorPath = '/tools/ai_content_detector';

export const getAiDetectorTitle = (t: TFunction): string =>
  t('tools:title.ai_content_detector', 'AI Content Detector');

export const AiHumanizerPath = '/tools/ai_text_humanizer';

export const getAiHumanizerTitle = (t: TFunction): string =>
  t('tools:title.ai_humanizer', 'AI Text Humanizer');

export const ParaphraseTextPath = '/tools/paraphrase_text';

export const getParaphraseTextTitle = (t: TFunction): string =>
  t('tools:title.paraphrase_text', 'Paraphrase Text');

export const SummariseTextPath = '/tools/summarize_text';

export const getSummariseTextTitle = (t: TFunction): string =>
  t('tools:title.summarise_text', 'Summarise Text');

export const RewriteArticlePath = '/tools/rewrite_article';

export const getRewriteArticleTitle = (t: TFunction): string =>
  t('tools:title.rewrite_article', 'Rewrite Article');

export const AiResearcherPath = '/ai_researcher';

export const getAiResearcherTitle = (t: TFunction): string =>
  t('tools:title.ai_researcher', 'AI Researcher');

export const AiResearcherSidebarKey = 'ai_researcher';

export const FactCheckerPath = '/tools/fact_checker';

export const getFactCheckerTitle = (t: TFunction): string =>
  t('tools:title.fact_checker', 'Fact Checker');

export const QuizGeneratorPath = '/tools/quiz_generator';

export const getQuizGeneratorTittle = (t: TFunction): string =>
  t('tools:title.quiz_generator', 'Quiz Generator');

export const ToolPath = '/tools/:slug';

export const getToolTitle = (t: TFunction): string =>
  t('tools:tools_item', 'Tools item');

export const validExtensions = ['docx', 'txt', 'pdf'];
export const MAX_FILE_SIZE = 10000000; //10MB

export const GRAMMAR_CHECK_TOOL_ID = '3a72d3d8-77e0-423e-9cf6-de3b346e0235';
export const AI_RESEARCHER_TOOL_ID = 'f6d33993-1bee-45ab-814f-e298e8b7fd58';

export const CITATION_MACHINE_TOOL_ID = '289c2a3c-2fd8-4714-bc81-635215ec6613';

export const SUMMARIZE_TOOL_ID = '8928337a-ca77-4fd2-a161-b5572b508fa6';

export const QUIZ_GENERATOR_TOOL_ID = 'a724acef-8aa7-4ec0-8bb0-c57c9d8076ab';

export const getFormSubmitButtonTextConfig = (
  t: TFunction,
): Record<string, string | ReactElement> => ({
  [GRAMMAR_CHECK_TOOL_ID]: t('general:check', 'Check'),
});

export const WIDE_FORM_REQUEST_TOOLS = [GRAMMAR_CHECK_TOOL_ID];

export const AVAILABLE_IN_EXTENSION_TOOL_ID = [
  GRAMMAR_CHECK_TOOL_ID,
  SUMMARIZE_TOOL_ID,
];

export const IsToolLimitHighLightShowed = 'is_tool_limit_highlight_tutorial';
export const IsToolLimitV2HighLightShowed =
  'is_tool_limit_v2_highlight_tutorial';

export const getToolsCommonHighlightConfig = (
  t: TFunction,
): Record<
  | HIGHLIGHT.TOOLS_OUTPUT_COUNTER
  | HIGHLIGHT.DETECTORS_MONTHLY_LIMIT
  | HIGHLIGHT.DETECTORS_MONTHLY_LIMIT_V2,
  HighLightConfigType
> => {
  return {
    [HIGHLIGHT.TOOLS_OUTPUT_COUNTER]: {
      title: t(
        'highlight:tools_output_counter.title',
        'Need more results for one input?',
      ),
      text: t(
        'highlight:tools_output_counter.text',
        'Receive up to 5 response variations for your one request.',
      ),
      onShowCallBack: (): void => {
        analytics.trackEvent('navigation - highlight appear', {
          scenario: 'Outputs - regeneration',
        });
      },
      onSubmitCallback: (updateUserExtraFields): void => {
        analytics.trackEvent('navigation - highlight click', {
          scenario: 'Outputs - regeneration',
        });
        updateUserExtraFields({
          highlights: {
            [HIGHLIGHT.TOOLS_OUTPUT_COUNTER]: 1,
          },
        });
      },
    },
    [HIGHLIGHT.DETECTORS_MONTHLY_LIMIT]: {
      title: t('highlight:detectors.limit.title', 'Monthly Word Balance'),
      text: t(
        'highlight:detectors.limit.text',
        'Amount of words available for you to run through AI Content Detector, AI Text Humanizer, and Plagiarism Checker. The balance refills with 80,000 words each month on Monthly Plan and unlimited on Annual Plan',
      ),
      // placement: 'bottom-start',
      buttonText: t('general:next', 'Next'),
    },
    [HIGHLIGHT.DETECTORS_MONTHLY_LIMIT_V2]: {
      title: t('highlight:detectors.limit_v2.title', 'Extra Words Are Added'),
      text: <WordsLimitV2ExplanationText />,
    },
  };
};
