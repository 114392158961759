import { PaletteMode, PaletteOptions } from '@mui/material';

import { colors } from '~/theme/colors';

const defaultBaseColors = {
  primary: {
    700: colors.primary_30,
    500: colors.primary_40,
    100: colors.primary_80,
  },
  secondary: {
    700: colors.neutral_20,
    500: colors.neutral_30,
    100: colors.primary_80,
  },
  tertiary: {},
  neutral: {
    700: colors.neutral_20,
    500: colors.neutral_30,

    100: colors.neutral_60,
  },
  gradient: {
    500: colors.gradient_40,
  },
  success: {
    500: colors.green_50,
  },
  warning: {
    500: colors.warning_50,
  },
  error: {
    700: colors.error_30,
    500: colors.error_40,
    100: colors.error_80,
  },
};

export const getPalette = (
  mode: PaletteMode,
): PaletteOptions & {
  common: Record<string, string>;
  secondary: Record<string, string>;
  primary: Record<string, string>;
  error: Record<string, string>;
  gradient: Record<string, string>;
} => ({
  ...(mode === 'light'
    ? {
        common: {
          black: colors.black,
          white: colors.white,
        },
        mode: 'light',
        background: {
          paper: colors.white,
        },
        primary: {
          main: colors.primary_40,
          ...defaultBaseColors.primary,
        },
        secondary: {
          main: colors.neutral_40,
          ...defaultBaseColors.secondary,
        },
        tertiary: {
          ...defaultBaseColors.tertiary,
        },
        gradient: {
          main: colors.gradient_40,
          ...defaultBaseColors.gradient,
        },
        neutral: {
          main: colors.neutral_40,
          ...defaultBaseColors.neutral,
        },
        success: {
          main: colors.green_50,
          ...defaultBaseColors.success,
        },
        warning: {
          main: colors.warning_50,
          ...defaultBaseColors.warning,
        },
        error: {
          main: colors.error_40,
          ...defaultBaseColors.error,
        },
        surface: {
          main: colors.white,
        },
      }
    : {
        common: {
          black: colors.white,
          white: colors.black,
        },
        mode: 'dark',
        background: {
          paper: colors.black,
        },
        primary: {
          main: colors.primary_40,
          ...defaultBaseColors.primary,
        },
        secondary: {
          main: colors.neutral_40,
          ...defaultBaseColors.secondary,
        },
        tertiary: {
          ...defaultBaseColors.tertiary,
        },
        gradient: {
          main: colors.gradient_40,
          ...defaultBaseColors.gradient,
        },
        neutral: {
          main: colors.neutral_40,
          ...defaultBaseColors.neutral,
        },
        success: {
          main: colors.green_50,
          ...defaultBaseColors.success,
        },
        warning: {
          main: colors.warning_50,
          ...defaultBaseColors.warning,
        },
        error: {
          main: colors.error_30,
          ...defaultBaseColors.error,
        },
        surface: {
          main: colors.black,
        },
      }),
});
