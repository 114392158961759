import React, { ReactElement, useMemo } from 'react';

import { Icon as SidebarIcon } from '@iconify/react';
import { flowRight } from 'lodash';

import { ReactComponent as ResearcherIcon } from '~/assets/icons/researcher-sidebar-icon.svg';
import router, { RouteItemType } from '~/core/router';
import { sidebar } from '~/core/sidebar';
import {
  withHomeScreenRetentionLayout,
  withMainLayout,
  withMainSimpleLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import { getNewLabel } from '~/modules/image-generator/constants';
import {
  AdvancedArticleGeneratorPath,
  AiDetectorPath,
  AiHumanizerPath,
  AiResearcherPath,
  AiResearcherSidebarKey,
  FactCheckerPath,
  getAdvancedArticleGeneratorTitle,
  getAiDetectorTitle,
  getAiHumanizerTitle,
  getAiResearcherTitle,
  getFactCheckerTitle,
  getPaperWriterTitle,
  getParaphraseTextTitle,
  getPlagiarismCheckerTitle,
  getQuizGeneratorTittle,
  getRewriteArticleTitle,
  getSummariseTextTitle,
  getToolsGalleryTitle,
  getToolTitle,
  PaperWriterPath,
  ParaphraseTextPath,
  PlagiarismCheckerPath,
  QuizGeneratorPath,
  RewriteArticlePath,
  SummariseTextPath,
  ToolPath,
  ToolsGalleryPath,
  ToolsSidebarKey,
} from '~/modules/tools/constants';
import { checkIsAiResearcherAccessible } from '~/modules/tools/views/AiResearcher/helpers/helpers';
import analytics from '~/services/analytics';

const AiResearcher = lazyComponentLoader(() => import('./views/AiResearcher'));

const ToolsGallery = lazyComponentLoader(() => import('./views/ToolsGallery'));
const Tool = lazyComponentLoader(() => import('./views/Tool'));

const AdvancedArticleGenerator = lazyComponentLoader(
  () => import('./views/AdvancedArticleGenerator'),
);

const PlagiarismCheckerV2 = lazyComponentLoader(
  () => import('./views/variant2/PlagiarismCheckerV2'),
);

const FactChecker = lazyComponentLoader(() => import('./views/FactChecker'));

const QuizGenerator = lazyComponentLoader(
  () => import('./views/QuizGenerator'),
);

const AiDetectorV2 = lazyComponentLoader(
  () => import('./views/variant2/AiDetectorV2'),
);

const AiDetectorV3 = lazyComponentLoader(
  () => import('./views/variant2/AiDetectorV3'),
);

const AiHumanizerV2 = lazyComponentLoader(
  () => import('./views/variant2/AiHumanizerV2'),
);

const ParaphraseTextV3 = lazyComponentLoader(
  () => import('./views/variant2/ParaphraseTextV3'),
);

const SummarizeText = lazyComponentLoader(
  () => import('./views/variant2/SummarizeText'),
);

const RewriteArticle = lazyComponentLoader(
  () => import('./views/variant2/RewriteArticle'),
);

const ToolsGalleryRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(ToolsGallery),
  path: ToolsGalleryPath,
  getTitle: getToolsGalleryTitle,
};

const AdvancedArticleGeneratorRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AdvancedArticleGenerator),
  path: AdvancedArticleGeneratorPath,
  getTitle: getAdvancedArticleGeneratorTitle,
};

const PaperWriterRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AdvancedArticleGenerator),
  path: PaperWriterPath,
  getTitle: getPaperWriterTitle,
};

const PlagiarismCheckerRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainSimpleLayout,
  )(PlagiarismCheckerV2),
  path: PlagiarismCheckerPath,
  getTitle: getPlagiarismCheckerTitle,
};

const FactCheckerRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(FactChecker),
  path: FactCheckerPath,
  getTitle: getFactCheckerTitle,
};

const QuizGeneratorRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(QuizGenerator),
  path: QuizGeneratorPath,
  // checkAvailability: checkIsQuizGeneratorAccessible,
  getTitle: getQuizGeneratorTittle,
};

const AiDetectorRoute: RouteItemType = {
  Component: () => {
    // const { backend_ab_tests } = useAppSelector((state) => state.profile);
    const isDetectorV3 = true;

    const ComponentToRender = isDetectorV3 ? AiDetectorV3 : AiDetectorV2;

    const WrappedComponent = useMemo(() => {
      const layouts = [
        withPWAModalLayout,
        withSubModalsLayout,
        withOnboardingLayout,
        withHomeScreenRetentionLayout,
        ...(isDetectorV3 ? [withMainSimpleLayout] : [withMainLayout]),
      ];

      return flowRight(...layouts)(ComponentToRender);
    }, [isDetectorV3]);

    return <WrappedComponent />;
  },
  path: AiDetectorPath,
  getTitle: getAiDetectorTitle,
};

const AiHumanizerRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainSimpleLayout,
  )(AiHumanizerV2),
  path: AiHumanizerPath,
  getTitle: getAiHumanizerTitle,
};

const ParaphraseTextRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(ParaphraseTextV3),
  path: ParaphraseTextPath,
  getTitle: getParaphraseTextTitle,
};

const SummarizeTextRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(SummarizeText),
  path: SummariseTextPath,
  getTitle: getSummariseTextTitle,
};

const RewriteArticleRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(RewriteArticle),
  path: RewriteArticlePath,
  getTitle: getRewriteArticleTitle,
};

const AiResearcherRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(AiResearcher),
  path: AiResearcherPath,
  checkAvailability: checkIsAiResearcherAccessible,
  getTitle: getAiResearcherTitle,
};

export const ToolRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(Tool),
  path: ToolPath,
  getTitle: getToolTitle,
};

router.addPrivateRoutes([
  ToolsGalleryRoute,
  AdvancedArticleGeneratorRoute,
  PaperWriterRoute,
  PlagiarismCheckerRoute,
  AiDetectorRoute,
  AiHumanizerRoute,
  ParaphraseTextRoute,
  SummarizeTextRoute,
  RewriteArticleRoute,
  AiResearcherRoute,
  FactCheckerRoute,
  QuizGeneratorRoute,
  ToolRoute,
]);

sidebar.addItems({
  a: [
    {
      order: 2,
      key: ToolsSidebarKey,
      path: ToolsGalleryRoute.path,
      getTitle: ToolsGalleryRoute.getTitle,
      onClickCb: (): void => {
        analytics.trackEvent('side_menu_click', {
          button: 'tools',
        });
      },
      Icon: ({ isActive }): ReactElement => {
        return (
          <SidebarIcon
            icon={isActive ? `flowbite:grid-solid` : `flowbite:grid-outline`}
          />
        );
      },
    },
    {
      order: 4,
      key: AiResearcherSidebarKey,
      path: AiResearcherRoute.path,
      getTitle: AiResearcherRoute.getTitle,
      getLabel: getNewLabel,
      checkAvailability: checkIsAiResearcherAccessible,
      onClickCb: (): void => {
        analytics.trackEvent('side_menu_click', {
          button: 'ai_researcher',
        });
      },
      Icon: (): ReactElement => {
        return (
          <ResearcherIcon
            style={{
              width: 32,
              height: 32,
              marginLeft: -4,
              marginTop: -4,
            }}
          />
        );
      },
    },
  ],
});
