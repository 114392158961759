import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
} from '@mui/material';

import { colors } from '~/theme/colors';

export type ButtonProps = MuiButtonProps;

export enum ButtonShape {
  PILL = 'pill',
  ROUNDED = 'rounded',
}

export const baseButtonStyles = (shape: ButtonShape): Record<string, any> => {
  return {
    '&': {
      boxSizing: 'border-box',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      borderRadius: shape === ButtonShape.ROUNDED ? '8px' : '100px',
      textTransform: 'none',
      boxShadow: 'none',
      transition: '250ms ease-in-out',
      minWidth: 'unset',
      minHeight: 'unset',

      '@media (hover: hover)': {
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    '&.MuiButton-containedPrimary': {
      color: colors.white,
      background: colors.primary_40,

      '@media (hover: hover)': {
        '&:hover': {
          background: '#4A67B4',
          boxShadow:
            '0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30)',
        },
      },
    },
    '&.MuiButton-containedSecondary': {
      color: colors.black,
      background: colors.primary_80,

      '@media (hover: hover)': {
        '&:hover': {
          background: '#CFD0E9',
          boxShadow:
            '0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30)',
        },
      },
    },
    '&.MuiButton-containedTertiary': {
      color: colors.black,
      background: 'rgba(0, 0, 0, 0.1)',

      '@media (hover: hover)': {
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },
    '&.MuiButton-containedNeutral': {
      color: colors.primary_40,
      background: colors.neutral_80,
      boxShadow:
        '0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30)',

      '@media (hover: hover)': {
        '&:hover': {
          background: '#E9E5F0',
          boxShadow:
            '0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30)',
        },
      },
    },
    '&.MuiButton-containedSuccess': {
      color: colors.white,
    },
    '&.MuiButton-outlinedPrimary': {
      color: colors.primary_40,
      background: 'transparent',
      border: 'none',
      boxShadow: `0 0 0 1px inset ${colors.neutral_30}`,

      '@media (hover: hover)': {
        '&:hover': {
          background: '#EFEAF6',
        },
      },
      '&:disabled': {
        boxShadow: 'unset',
      },
    },
    '&.MuiButton-outlinedSecondary': {
      color: colors.primary_40,
      background: 'transparent',
      border: 'none',
      boxShadow: '0 0 0 1px inset #E2E2EC',

      '@media (hover: hover)': {
        '&:hover': {
          background: '#EFEAF6',
        },
      },
      '&:disabled': {
        boxShadow: 'unset',
      },
    },
    '&.MuiButton-outlinedNeutral': {
      color: colors.primary_40,
      background: 'transparent',
      border: 'none',
      boxShadow: '0 0 0 1px inset #C2D5EE',

      '@media (hover: hover)': {
        '&:hover': {
          background: 'rgba(255, 255, 255, 0.5)',
        },
      },
      '&:disabled': {
        background: 'unset',
        boxShadow: `0 0 0 1px inset ${colors.neutral_80}`,
      },
    },
    '&.MuiButton-outlinedError': {
      color: colors.error_30,
      background: 'transparent',
      border: 'none',
      boxShadow: `0 0 0 1px inset ${colors.error_30}`,

      '@media (hover: hover)': {
        '&:hover': {
          background: colors.error_30,
          color: colors.white,
        },
      },
      '&:disabled': {
        background: 'unset',
        boxShadow: `0 0 0 1px inset ${colors.error_80}`,
      },
    },
    '&.MuiButton-textPrimary': {
      color: colors.primary_40,

      '@media (hover: hover)': {
        '&:hover': {
          background: 'rgba(103, 80, 164, 0.08)',
        },
      },
      '&:disabled': {
        color: colors.neutral_60,
        background: 'unset',
      },
    },
    '&.MuiButton-sizeDoubleExtraSmall': {
      padding: '7px 12px',
      lineHeight: 1,
    },
    '&.MuiButton-sizeExtraSmall': {
      padding: '10px 16px',
    },
    '&.MuiButton-sizeSmall': {
      padding: '10px 24px',
    },
    '&.MuiButton-sizeMedium': {
      padding: '14px 24px',
    },
    '&.MuiButton-sizeLarge': {
      padding: '18px 24px',
    },
    '&:disabled': {
      color: colors.neutral_60,
      background: colors.neutral_80,
    },
  };
};

const Button = styled(MuiButton)<ButtonProps & { shape?: ButtonShape }>(
  ({ shape = ButtonShape.PILL }) => ({
    ...baseButtonStyles(shape),

    '&.MuiButton-containedGradient': {
      color: colors.white,
      background: colors.gradient_40,

      '@media (hover: hover)': {
        '&:hover': {
          boxShadow:
            '0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.30)',
        },
      },
    },
  }),
);

export default Button;
