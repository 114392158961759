import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const getBalanceExplanationList = (data: {
  t: TFunction;
  period: string;
  periodically: string;
}): string[] => {
  const { t, period, periodically } = data;

  return [
    t('tools:words_limit_v2.popper_explanation_text[0]', {
      period: period,
      periodically: periodically,
      defaultValue:
        '{{periodically}} words are displayed in blue color, and extra words you added are displayed in green',
    }),
    t('tools:words_limit_v2.popper_explanation_text[1]', {
      period: period.toLowerCase(),
      periodically: periodically,
      defaultValue:
        '{{periodically}} words are used first, then extra words. {{periodically}} words are refilled each {{period}}',
    }),
    t(
      'tools:words_limit_v2.popper_explanation_text[2]',
      'Extra words remain available until used out',
    ),
  ];
};

const ExplanationText: FC = () => {
  const { t } = useTranslation('tools');
  const profile = useAppSelector((state) => state.profile);

  const period = t(
    `general:${profile.user_product.regularity}`,
    profile.user_product.regularity,
  );
  const periodically = t(
    `general:${profile.user_product.regularity}ly`,
    `${profile.user_product.regularity.charAt(0).toUpperCase() + profile.user_product.regularity.slice(1)}ly`,
  );

  return (
    <div>
      <ul className={styles.list}>
        {getBalanceExplanationList({
          t,
          period,
          periodically,
        }).map((text, idx) => {
          return (
            <li key={idx} className={styles.list__item}>
              <Typography
                variant={Typographies.BODY_MEDIUM}
                component="div"
                className={styles.text}
              >
                {text}
              </Typography>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ExplanationText;
